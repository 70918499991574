import React, { useState } from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ContactHeader from './components/contact/contact-header';
import ContactBody from './components/contact/contact-form';
import './components/contact/styles.scss';
import FormSubmission from './components/contact/contact-submission';

export default function Contact() {
  const [toast, setToast] = useState(false);
  return (
    <Layout>
      <Seo title="Contact Us" />
      <ContactHeader />
      <ContactBody setToast={setToast} />
      <FormSubmission toast={toast} />
    </Layout>
  );
}
